import * as React from 'react';
import ClassNames from '@streamloots/classnames';
import theme from './form.scss';

const classNames = ClassNames(theme);

type LabelProps = {
  className?: string;
  children: React.ReactNode;
  error?: string;
  id?: string;
  required?: boolean;
  flexMode?: boolean;
};

export const Label = ({ className = '', error, children, id, required }: LabelProps): JSX.Element => (
  <label
    className={classNames({
      'label': true,
      'label__error': error,
      'label--flex': error,
      [className]: className,
    })}
    htmlFor={id}
  >
    {children} {required && '*'}
  </label>
);
