import { connect } from 'react-redux';
import { authSelectors } from 'services/auth/selectors';
import rewindSelectors from 'services/rewind/selectors';
import { useTranslation } from 'utils/translation';
import { RewindSelectorOutput } from 'services/rewind/types';
import ClassNames from '@streamloots/classnames';
import Icon from 'ui-library/icons';
import { Link } from 'ui-library/link';
import { urls } from 'services/routing';
import { Campaings, CampaingActive } from 'domain/campaing-active';
import REWIND from './assets/rewind-logo.svg';
import theme from './banner-left-menu.scss';

interface MapStateToProps {
  isUserAuthenticated: boolean;
  rewind: RewindSelectorOutput;
}

const classNames = ClassNames(theme);

const RewindLeftMenuBase = ({ isUserAuthenticated, rewind }: MapStateToProps): JSX.Element | null => {
  const { t } = useTranslation('dashboard');
  if (!CampaingActive.check(Campaings.REWIND)) {
    return null;
  }

  if (!isUserAuthenticated) {
    return null;
  }

  if (!rewind || (rewind?.viewer === null && rewind?.streamer === null)) {
    return null;
  }

  return (
    <Link
      className={classNames({
        'banner-left-menu': true,
      })}
      to={urls().REWIND_SOCIAL}
    >
      <div className={classNames('banner-left-menu__logo-container')}>
        <img className={classNames('banner-left-menu__logo')} src={REWIND} alt={t('dashboard:rewind.chipiTitle')} />
        <div>
          <Icon id="chevron-right" />
        </div>
      </div>
      <p className={classNames('banner-left-info')}>{t('dashboard:rewind.chipiCTA')}</p>
    </Link>
  );
};

const mapStateToProps = (state): MapStateToProps => ({
  isUserAuthenticated: authSelectors.isUserAuthenticated(state),
  rewind: rewindSelectors.rewind(state),
});

export const RewindLeftMenu = connect(mapStateToProps)(RewindLeftMenuBase);
