import config from 'config';

const HALLOWEEN = {
  id: 'HALLOWEEN2024',
  startDate: config.app.env === 'production' ? '2024-10-18T06:00:00.000Z' : '2024-10-04T07:00:00.000Z',
  endDate: '2024-11-04T07:00:00.000Z',
};
const CHRISTMAS = {
  id: 'christmas2024',
  startDate: config.app.env === 'production' ? '2024-12-13T07:00:00.000Z' : '2023-12-01T08:00:00.000Z',
  endDate: '2025-01-02T07:00:00.000Z',
};

export const FIESTAS = {
  ANNIVERSARY1: {
    id: '2022-01-14-aniversary-fiesta',
    startDate: config.app.env === 'production' ? '2022-01-14T08:00:00.000Z' : '2022-01-13T08:00:00.000Z',
    endDate: '2022-01-17T07:59:00.000Z',
  },
  CHRISTMAS_2021: {
    id: 'christmas2021',
    startDate: '2021-12-03T11:00:00.000Z', // 2021-12-03 12:00 pm on Europe/madrid
    endDate: config.app.env === 'production' ? '2022-01-01T11:00:00.000Z' : '2021-12-05T11:00:00.000Z', // 2021-01-01 12:00 pm Europe/Madrid;
  },
  REWIND: {
    id: 'Rewind 2024',
    startDate: config.app.env === 'production' ? '2024-12-18T07:00:00.000Z' : '2023-12-01T08:00:00.000Z',
    endDate: '2024-12-28T07:00:00.000Z',
  },
  VALENTINES_2023: {
    id: 'Valentines 2023',
    startDate: config.app.env === 'production' ? '2023-02-10T08:00:00.000Z' : '2023-02-10T10:00:00.000Z',
    endDate: '2023-02-18T08:00:00.000Z',
  },
  COUPONS_FIESTA: {
    id: 'Second fiesta may 22',
    startDate: config.app.env === 'production' ? '2022-05-27T07:00:00.000Z' : '2022-05-26T08:00:00.000Z',
    endDate: '2022-06-01T07:00:00.000Z',
  },
  PROMOTION_NO_FEES_GLOBAL: {
    id: 'Apr 22 no fees streamers choice',
    startDate: config.app.env === 'production' ? '2022-04-22T07:00:00.000Z' : '2022-04-21T08:00:00.000Z',
    endDate: '2022-04-25T07:00:00.000Z',
  },
  PROMOTION_MARATHON: {
    id: 'Marathon giveaway March 2022',
    startDate: '2022-03-18T08:00:00.000Z',
    endDate: '2022-04-01T08:00:00.000Z',
  },
  BLACK_FRIDAY_2022: {
    id: 'Give back friday 2022',
    startDate: config.app.env === 'production' ? '2022-11-25T07:00:00.000Z' : '2022-11-20T00:00:00.000Z',
    awarenessStartDate: '2022-11-17T23:00:00.000Z',
    endDate: '2022-11-28T07:00:00.000Z',
  },
  SUB_PROMO: {
    id: 'sub90Promo202306',
    startDate: config.app.env === 'production' ? '2023-06-09T06:00:00.000Z' : '2023-06-08T06:00:00.000Z',
    endDate: '2023-06-22T06:00:00.000Z',
  },
  CHRISTMAS,
  TICKETS_CAMPAIGN: CHRISTMAS,
  GIVEAWAY: {
    id: 'thronePartnershipAug24',
    startDate: config.app.env === 'production' ? '2024-08-26T16:00:00.000Z' : '2024-06-18T00:00:00.000Z',
    endDate: '2024-09-25T16:00:00.000Z',
  },
  BATTLE_PASS_CAMPAIGN: {
    id: 'summerFiesta202406',
    // id: 'christmas2023',
    startDate: config.app.env === 'production' ? '2024-06-28T00:00:00.000Z' : '2024-06-18T00:00:00.000Z',
    endDate: '2024-07-15T00:00:00.000Z',
  },
  HALLOWEEN,
};
