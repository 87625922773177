import { useMemo } from 'react';
import ClassNames from '@streamloots/classnames';
import { PanelWithoutHeader } from 'ui-library/panel';
import { VerticalListItem, VerticalList } from 'ui-library/vertical-list';
import { useTranslation } from 'utils/translation';
import { ExplanationHeaderVariations, ExplanationPanelHeader } from 'ui-library/explanation-panel';
import { usePageSelectors } from 'services/page';
import Button from 'ui-library/button';
import theme from './alert-provider.scss';
import { ALERT_PROVIDERS_SECTIONS } from './constants';

const classNames = ClassNames(theme);

export const TipsForAlerts = (): JSX.Element => {
  const {
    page: { modules },
  } = usePageSelectors();
  const { t } = useTranslation(['dashboard', 'alert']);

  const options = useMemo(() => {
    const tips: string[] = [];
    if (modules?.cards.enabled) {
      tips.push(
        ALERT_PROVIDERS_SECTIONS.cardAndPacks,
        ALERT_PROVIDERS_SECTIONS.legendary,
        ALERT_PROVIDERS_SECTIONS.tts,
      );
    }

    if (modules?.subscriptions.enabled) {
      tips.push(ALERT_PROVIDERS_SECTIONS.subscriptions);
    }

    tips.push(ALERT_PROVIDERS_SECTIONS.text);

    if (modules?.cards.enabled) {
      tips.push(ALERT_PROVIDERS_SECTIONS.powers);
    }

    tips.push(ALERT_PROVIDERS_SECTIONS.automaticPromotion);
    tips.push(ALERT_PROVIDERS_SECTIONS.profanity);

    return tips;
  }, [modules]);

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    window.scrollTo({ top: element.offsetTop });
  };

  return (
    <PanelWithoutHeader>
      <ExplanationPanelHeader
        className={classNames('alert-provider__tips-image')}
        variation={ExplanationHeaderVariations.Candy}
      />
      <h2>{t('alert:tips.title')}</h2>

      <VerticalList isOrderedList>
        {options.map((option, i: number) => (
          <VerticalListItem
            key={`alert-configurations-${option}-${i}`}
            title={
              <Button asLink onClick={() => scrollTo(option)}>
                {t(`alert:tips.${option}`)}
              </Button>
            }
          />
        ))}
      </VerticalList>
    </PanelWithoutHeader>
  );
};
