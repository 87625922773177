import { useState, useEffect, FunctionComponent } from 'react';
import ClassNames from '@streamloots/classnames';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { CampaingActive, Campaings } from 'domain/campaing-active';
import { withTranslation, TranslateInterface } from 'utils/translation';
import trackEventManager from 'utils/event-manager/trackEventManager';
import { rewindSelectors, RewindSelectorOutput } from 'services/rewind';
import { urls } from 'services/routing';
import FloatingAlert from 'ui-library/floating-image-alert';
import { Link } from 'ui-library/link';
import Button from 'ui-library/button';
import REWIND_2021_IMAGE from './assets/rewind.png';
import theme from './rewind-alert.scss';

const classNames = ClassNames(theme);

interface RewindAlertBaseOwnProps {
  rewind: RewindSelectorOutput;
}

const EVENT_ID = 'Rewind 2024';

interface RewindAlertBaseProps extends TranslateInterface, RewindAlertBaseOwnProps {}

const RewindAlertBase = ({ t, rewind }: RewindAlertBaseProps): JSX.Element | null => {
  const [show, setShow] = useState(false);

  const isCampaignTime = CampaingActive.check(Campaings.REWIND);
  console.log(rewind);
  useEffect(() => {
    if (
      !isCampaignTime ||
      !rewind ||
      rewind?.viewer?.reportImageUrl !== undefined ||
      rewind?.streamer?.reportImageUrl !== undefined
    ) {
      setShow(false);
      return;
    }

    setShow(true);
  }, [isCampaignTime, rewind]);

  useEffect(() => {
    if (show) {
      trackEventManager.floatingImageAlertViewed({ floatingAlertKey: EVENT_ID });
    }
  }, [show]);

  if (!isCampaignTime) {
    return null;
  }

  const handleClose = () => {
    setShow(false);
    trackEventManager.floatingImageAlertClicked({ floatingAlertKey: EVENT_ID, buttonId: 'close' });
  };

  const handleCtaClick = () => {
    setShow(false);
    trackEventManager.floatingImageAlertClicked({ floatingAlertKey: EVENT_ID, buttonId: 'cta' });
  };

  return (
    <FloatingAlert
      title={t('dashboard:rewind.chipiTitle')}
      description={<p className={classNames('description')}>{t('dashboard:rewind.chipiDescription')}</p>}
      img={REWIND_2021_IMAGE}
      show={show}
      onClose={handleClose}
    >
      <Button
        component={Link}
        to={urls().REWIND_SOCIAL}
        label={t('dashboard:rewind.chipiCTA')}
        size="sm"
        color="checkout"
        variant="filled"
        onClick={handleCtaClick}
      />
    </FloatingAlert>
  );
};

const mapStateToProps = state => {
  return {
    rewind: rewindSelectors.rewind(state),
  };
};

export const RewindAlert = compose<FunctionComponent>(
  withTranslation(['dashboard']),
  connect(mapStateToProps),
)(RewindAlertBase);
