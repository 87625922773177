import { useEffect, useState } from 'react';
import trackEventManager from 'utils/event-manager/trackEventManager';
import { discountCouponCookieManager } from 'services/coupons';
import stringFormat from 'helpers/stringFormat';
import { useUserActions, useUserSelectors } from 'services/user';
import { usePageSelectors } from 'services/page';

const TIME_TO_EXPIRE = 1000 * 60 * 60 * 2;
const LAST_EXPIRATION_TRESHOLD = new Date('2024-08-27T09:39:46.781Z');

export const resetExpiresAt = (expiresAt: string): boolean => {
  return new Date(expiresAt) < LAST_EXPIRATION_TRESHOLD;
};

export const hasCountdownExpired = (expiresAt: string) => {
  const expirationDate = new Date(expiresAt);
  return Date.now() > expirationDate.getTime();
};

export const getNewUserCouponDate = (): string => {
  const expirationMs = Date.now() + TIME_TO_EXPIRE;
  return new Date(expirationMs).toISOString();
};

export const useNewBuyerStreamerCoupon = (location = 'floating alert') => {
  const [show, setShow] = useState(false);
  const {
    page: { slug, newBuyerCouponCode, isOwner },
  } = usePageSelectors();
  const capitalizedSlug = stringFormat.capitalizeFirstLetter(slug);
  const { userPreferences } = useUserSelectors();
  const { newBuyerStreamerCoupon = {} } = userPreferences;
  const { updateUserPreferences } = useUserActions();
  const expiresAt = newBuyerStreamerCoupon[slug];
  const canSeeCoupon = Boolean(!isOwner && newBuyerCouponCode);
  const showCoupon = canSeeCoupon && show;

  useEffect(() => {
    if (!canSeeCoupon) {
      setShow(false);
      return;
    }

    if (!expiresAt || resetExpiresAt(expiresAt)) {
      updateUserPreferences({
        ...userPreferences,
        newBuyerStreamerCoupon: {
          ...newBuyerStreamerCoupon,
          [slug]: getNewUserCouponDate(),
        },
      });

      setShow(true);
      return;
    }

    if (!hasCountdownExpired(expiresAt)) {
      setShow(true);
    }

    // eslint-disable-next-line
  }, [canSeeCoupon]);

  useEffect(() => {
    if (showCoupon) {
      trackEventManager.trackEvent('new_streamer_buyer_coupon_viewed', { location, buttonId: 'apply' }, true);
    }
  }, [showCoupon, location]);

  const hideCoupon = () => {
    setShow(false);
  };

  const applyCoupon = () => {
    if (!newBuyerCouponCode) {
      return;
    }
    discountCouponCookieManager.setCouponCookie(newBuyerCouponCode);
    trackEventManager.trackEvent('new_streamer_buyer_coupon_clicked', { location, buttonId: 'apply' }, true);
  };

  return {
    newBuyerCouponCode,
    capitalizedSlug,
    show: showCoupon,
    applyCoupon,
    hideCoupon,
    expiresAt,
  };
};
