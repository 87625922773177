import { StrictOmit } from '@streamloots/streamloots-types';
import { ComponentProps } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom-v5-compat';

type ReactRouterLinkProps = ComponentProps<typeof ReactRouterLink>;

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & Pick<ReactRouterLinkProps, 'to'>;

export type LinkPropsBase = AnchorProps | ReactRouterLinkProps;

export interface LinkProps extends StrictOmit<LinkPropsBase, 'style'> {
  style?: React.CSSProperties;
}

const hasExternalProp = (props: LinkProps) =>
  (typeof props.to === 'string' && props.to.indexOf('http') > -1) ||
  (typeof props.to === 'object' && props.to.pathname && props.to.pathname.indexOf('http') > -1);

const hasDeeplinkProp = (props: LinkProps) =>
  (typeof props.to === 'string' && props.to.indexOf('://') > -1) ||
  (typeof props.to === 'object' && props.to.pathname && props.to.pathname.indexOf('://') > -1);

export const Link = (props: LinkProps): JSX.Element => {
  const isExternal = hasExternalProp(props);
  const isDeepLink = hasDeeplinkProp(props);
  if (!isExternal && !isDeepLink) {
    return <ReactRouterLink {...props} />;
  }

  const { to, ...anchorProps } = props as AnchorProps;

  const href = typeof to === 'object' ? `${to.pathname}${to.search || ''}${to.hash || ''}` : to;

  return (
    <a href={href as string} target="_blank" rel="noopener noreferrer" {...anchorProps}>
      {props.children}
    </a>
  );
};
