const DEFAULT_API_URL = 'https://api.staging.streamloots.com';
const DEFAULT_FACEBOOK_CLIENT_ID = '1377829832277106';
const DEFAULT_PAYPAL_ENV = 'sandbox';
const DEFAULT_PAYPAL_PRODUCTION_CLIENT_ID =
  'AfGkPtB6DXcaozNLGK3ifFmx4ZM58lFrv8NgzwhCctdHJuVEo67Yi6opKjJc45VtAwkYCPNxcSuvqPRk';
const DEFAULT_PAYPAL_SANDBOX_CLIENT_ID =
  'AZhUKD8_-DUUYAdJXaWH9TitKG0aDyAOrHUoj39KoIWzJnKDRrBcXc6DRuWAIWMJ2vCKvGRF5q52PNPY';
const DEFAULT_STREAMLABS_CLIENT_ID = 'gnXMYa7m09dS1fZVfVtCjw2zMMjBJowc7AaEDMan';
const DEFAULT_STREAMLABS_REDIRECT_URI = 'http://localhost:3000/oauth/streamlabs';
const DEFAULT_STRIPE_API_PUBLISHABLE_KEY = 'pk_test_z2Y44FzuQTYPm7R9OzPYVSuo';
const DEFAULT_STREAMLOOTS_CLIENT_ID = '98b0ce5d-13df-4f3b-b7e1-a9dfb1ad67da';
const DEFAULT_STREAMLOOTS_REDIRECT_URI = 'http://localhost:3000/sign-in';
const DEFAULT_STREAMLOOTS_DOMAIN = 'localhost';
const DEFAULT_STREAMLABS_SCOPES = 'alerts.create points.write';
const DEFAULT_STREAMLABS_RESPONSE_TYPE = 'code';
const STAGING_URL = 'https://www.staging.streamloots.com/';
const PRODUCTION_URL = 'https://www.streamloots.com/';

const GOOGLE_ANALYTICS_TRACKING_ID_DEV = 'UA-72182898-9';
const GOOGLE_TAG_MANAGER_TRACKING_ID_DEV = 'GTM-N8VXV7S';
const FACEBOOK_PIXEL_ID_DEV = '244245772787929';

const SNOWPLOW_TRACKER_ID = 'streamloots-prod1';
const SNOWPLOW_ENDPOINT = 'com-streamloots-prod1.mini.snplow.net';
const SNOWPLOW_APP_ID = 'www';
const DEFAULT_AMPLITUDE_API_KEY = 'e7f25546b208671064cfe4119e0148d0';
const DEFAULT_MIXPANEL_PROJECT_TOKEN = '6d3441a3d660980e98de2cbbadeb0f31';

const GOOGLE_FONTS_API_KEY = 'AIzaSyDTtvAW-okstGSm1QJFpvVQCwGuIYtwk5A';

const DEFAULT_SENTRY_DSN = 'https://aa26a048e7ac4660899a49d0ec25849a@sentry.io/1483851';
const DEFAULT_SENTRY_ENV = 'development';
const DEFAULT_ENV = 'development';

const DEFAULT_CDN_CLOUDINARY_URL = 'https://res.cloudinary.com/streamloots';
const DEFAULT_CDN_WSRV_URL = 'https://cdno.streamloots.com/';
const DEFAULT_CDN_WSRV_SUPPORTED_IMAGE_TYPES = '.bmp,.jpeg,.jpg,.png';
const DEFAULT_CDN_AWS_UPLOADS_URL = 'https://cdn.streamloots.com';
const DEFAULT_CDN_AWS_STATIC_URL = 'https://static.streamloots.com';
const USERFLOW_TOKEN = 'ct_qovkroa5sjhellr6kirnojypqe';

const AMPLITUDE_DISABLED = process.env.AMPLITUDE_DISABLED === 'true';

const configuration = {
  host: process.env.NODE_HOST || 'localhost', // Define your host from 'package.json'
  port: process.env.PORT,
  isTest: process.env.NODE_ENV === 'test',
  ogUrl: process.env.NODE_ENV !== 'production' ? STAGING_URL : PRODUCTION_URL,
  facebook: {
    appId: process.env.FACEBOOK_CLIENT_ID || DEFAULT_FACEBOOK_CLIENT_ID,
    pixelId: process.env.FACEBOOK_PIXEL_ID || FACEBOOK_PIXEL_ID_DEV,
  },
  streamlabs: {
    clientId: process.env.STREAMLABS_CLIENT_ID || DEFAULT_STREAMLABS_CLIENT_ID,
    redirectUri: process.env.STREAMLABS_REDIRECT_URI || DEFAULT_STREAMLABS_REDIRECT_URI,
    scopes: process.env.STREAMLABS_SCOPES || DEFAULT_STREAMLABS_SCOPES,
    responseType: process.env.STREAMLABS_RESPONSE_TYPE || DEFAULT_STREAMLABS_RESPONSE_TYPE,
  },
  paypal: {
    env: process.env.PAYPAL_ENV || DEFAULT_PAYPAL_ENV,
    productionClient: process.env.PAYPAL_PRODUCTION_CLIENT_ID || DEFAULT_PAYPAL_PRODUCTION_CLIENT_ID,
    sandboxClient: process.env.PAYPAL_SANDBOX_CLIENT_ID || DEFAULT_PAYPAL_SANDBOX_CLIENT_ID,
  },
  paypalUs: {
    env: process.env.PAYPAL_ENV || DEFAULT_PAYPAL_ENV,
    productionClient: process.env.PAYPAL_PRODUCTION_US_CLIENT_ID || DEFAULT_PAYPAL_PRODUCTION_CLIENT_ID,
    sandboxClient: process.env.PAYPAL_SANDBOX_US_CLIENT_ID || DEFAULT_PAYPAL_SANDBOX_CLIENT_ID,
  },
  amplitude: {
    apiKey: process.env.AMPLITUDE_API_KEY || DEFAULT_AMPLITUDE_API_KEY,
    disabled: AMPLITUDE_DISABLED,
  },
  mixpanel: {
    projectToken: process.env.MIXPANEL_PROJECT_TOKEN || DEFAULT_MIXPANEL_PROJECT_TOKEN,
  },
  snowplow: {
    trackerId: process.env.SNOWPLOW_TRACKER_ID || SNOWPLOW_TRACKER_ID,
    endpoint: process.env.SNOWPLOW_ENDPOINT || SNOWPLOW_ENDPOINT,
    app_id: process.env.SNOWPLOW_APP_ID || SNOWPLOW_APP_ID,
  },
  googleAnalytics: {
    trackingId: process.env.GOOGLE_ANALYTICS_TRACKING_ID || GOOGLE_ANALYTICS_TRACKING_ID_DEV,
  },
  googleTagManager: {
    trackingId: process.env.GOOGLE_TAG_MANAGER_TRACKING_ID || GOOGLE_TAG_MANAGER_TRACKING_ID_DEV,
  },
  googleFonts: {
    apiKey: process.env.GOOGLE_FONTS_API_KEY || GOOGLE_FONTS_API_KEY,
  },
  stripe: {
    apiKey: process.env.STRIPE_API_PUBLISHABLE_KEY || DEFAULT_STRIPE_API_PUBLISHABLE_KEY,
  },
  streamloots: {
    api: process.env.API_URL || DEFAULT_API_URL,
    clientId: process.env.STREAMLOOTS_CLIENT_ID || DEFAULT_STREAMLOOTS_CLIENT_ID,
    domain: process.env.STREAMLOOTS_DOMAIN || DEFAULT_STREAMLOOTS_DOMAIN,
    redirectUri: process.env.STREAMLOOTS_REDIRECT_URI || DEFAULT_STREAMLOOTS_REDIRECT_URI,
  },
  sentry: {
    dsn: process.env.SENTRY_DSN || DEFAULT_SENTRY_DSN,
    environment: process.env.SENTRY_ENV || DEFAULT_SENTRY_ENV,
    release: `www@${process.env.npm_package_version}`,
    dist: process.env.BUILD_SHORT_SHA,
  },
  app: {
    htmlAttributes: { lang: 'en' },
    title: 'Streamloots',
    titleTemplate: 'Streamloots',
    meta: [
      {
        name: 'description',
        content: '',
      },
    ],
    env: process.env.APP_ENV || DEFAULT_ENV,
    version: process.env.npm_package_version,
  },
  cdn: {
    CDN_CLOUDINARY_ENABLED: process.env.CDN_CLOUDINARY_ENABLED === 'true',
    CDN_CLOUDINARY_URL: process.env.CDN_CLOUDINARY_URL || DEFAULT_CDN_CLOUDINARY_URL,
    CDN_AWS_UPLOADS_URL: process.env.CDN_AWS_UPLOADS_URL || DEFAULT_CDN_AWS_UPLOADS_URL,
    CDN_AWS_STATIC_URL: process.env.CDN_AWS_STATIC_URL || DEFAULT_CDN_AWS_STATIC_URL,
    CDN_WSRV_ENABLED: process.env.CDN_WSRV_ENABLED === 'true',
    CDN_WSRV_SUPPORTED_IMAGE_TYPES:
      process.env.CDN_WSRV_SUPPORTED_IMAGE_TYPES || DEFAULT_CDN_WSRV_SUPPORTED_IMAGE_TYPES,
    CDN_WSRV_URL: process.env.CDN_WSRV_URL || DEFAULT_CDN_WSRV_URL,
  },
  flags: {
    APP_FLAGS_DISCOVER_ON_MENU_ENABLED:
      process.env.APP_ENV !== 'production' || process.env.APP_FLAGS_DISCOVER_ON_MENU_ENABLED === 'true',
  },
  userflow: {
    token: process.env.USERFLOW_TOKEN || USERFLOW_TOKEN,
  },
  modules: {
    DONATIONS_ENABLED: process.env.ENABLED_DONATIONS ? process.env.ENABLED_DONATIONS.toLowerCase() === 'true' : false,
  },
};

export default configuration;
