import { createSelector } from 'reselect';
import type { RewindFetchData, User } from 'model/indexTS';
import type { RewindStoreState, RewindSelectorOutput, RewindState, RewindData } from './types';

const rewindState = (state: RewindStoreState): RewindState => {
  return state.rewind;
};

const rewindUser = createSelector([rewindState], rewindStateInfo => rewindStateInfo.user);
const shareRewindUser = createSelector([rewindState], rewindStateInfo => rewindStateInfo.userRewind);

const shouldReturnViewer = viewer => {
  if (!viewer) {
    return undefined;
  }

  const { topSupportedStreamers, obtainedCollectionLootsCount, usedCardsCount, streamersWithOpenedLootsCount } = viewer;

  const NO_DATA = usedCardsCount === 0 && obtainedCollectionLootsCount === 0 && streamersWithOpenedLootsCount === 0;

  if (topSupportedStreamers.length === 0 || NO_DATA) {
    return undefined;
  }

  return viewer;
};

const shouldReturnStreamer = streamer => {
  if (!streamer) {
    return undefined;
  }

  const { topSupporters, usedCardsCount, viewersWithOpenedLootsCount, obtainedCollectionLootsCount } = streamer;

  const NO_DATA = usedCardsCount === 0 && viewersWithOpenedLootsCount === 0 && obtainedCollectionLootsCount === 0;

  if (topSupporters.length === 0 || NO_DATA) {
    return undefined;
  }

  return streamer;
};

const formatQuantityCards = total => (total > 1000 ? `${(total / 1000).toFixed(0).toString()}k` : `${total}`);

const formatRewindData = (info: RewindFetchData, rewindStateInfo: User): null | RewindSelectorOutput => {
  const viewer = shouldReturnViewer(info.viewer);
  const streamer = shouldReturnStreamer(info.streamer);

  if (!viewer && !streamer) {
    return null;
  }

  return {
    ...(viewer && {
      viewer: {
        topPercentile: viewer.topPercentile,
        username: rewindStateInfo.profile.username,
        chests: viewer.obtainedCollectionLootsCount,
        cards: formatQuantityCards(viewer.usedCardsCount),
        cardsQuantity: viewer.usedCardsCount,
        reportImageUrl: viewer.reportImageUrl,
        chestsOpenedByViewers: viewer.streamersWithOpenedLootsCount,
        topUser: viewer.topSupportedStreamers.map(user => {
          return user.profile.username;
        }),
      },
    }),
    ...(streamer && {
      streamer: {
        topPercentile: streamer.topPercentile,
        username: rewindStateInfo.profile.username,
        chests: streamer.obtainedCollectionLootsCount,
        cards: formatQuantityCards(streamer.usedCardsCount),
        cardsQuantity: streamer.usedCardsCount,
        reportImageUrl: streamer.reportImageUrl,
        chestsOpenedByViewers: streamer.viewersWithOpenedLootsCount,
        topUser: streamer.topSupporters.map(user => {
          return user.profile.username;
        }),
      },
    }),
  };
};

const rewind = createSelector<RewindStoreState, User | null, RewindSelectorOutput>(
  [rewindUser],
  (rewindStateInfo: User | null) => {
    const info = rewindStateInfo?.promotions?.rewind2024;

    if (info == null || rewindStateInfo?.profile == null) {
      return null;
    }

    return formatRewindData(info, rewindStateInfo);
  },
);

const rewindShareUser = createSelector<RewindStoreState, User | null, RewindSelectorOutput>(
  [shareRewindUser],
  (rewindStateInfo: User | null) => {
    const info = rewindStateInfo?.promotions?.rewind2024;

    if (info == null || rewindStateInfo?.profile == null) {
      return null;
    }

    return formatRewindData(info, rewindStateInfo);
  },
);

const selectors = {
  rewind,
  rewindShareUser,
};

export default selectors;
